import React, { useEffect, useState, useRef, useContext } from "react";
import PageWrapper from "../components/PageWrapper";
import Section from "../components/Section";
import { Text, MainTitle } from "../components/Core";
import { Col, Row, Container, Accordion } from "react-bootstrap";
import Seo from "./../components/Seo";
import Map from "ol/Map";
import View from "ol/View";
import Polygon from "ol/geom/Polygon";
import proj4 from "proj4";
import { isMobile } from "react-device-detect";
import { defaults as DefaultInteractions } from "ol/interaction";
import { Group as LayerGroup, Tile as TileLayer } from "ol/layer";
import { Icon, Style, Fill, Stroke } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import XYZ from "ol/source/XYZ";
import { fromLonLat, transformExtent } from "ol/proj";
import "./styles.scss";
import API from "../api/api";
import APINO from "../api/api-no";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import CheckAvailability from "../sections/index/CheckAvailability";
import netomniaCircleLogo from "../../src/assets/image/png/netomnia-circle-logo.png";
import phaseLive from "../../static/live.png";
import phaseInprogress from "../../static/inprogress.png";
import phase2023 from "../../static/2023.png";
import { getProperty, groupBy } from "./../utils/helperFn";
import { graphql, useStaticQuery } from "gatsby";
import GlobalContext from "../context/GlobalContext";

const Rollout = () => {

  const gContext = useContext(GlobalContext)

  const data = useStaticQuery(graphql`
        query strapiRolloutQuery {
            strapiPageHeadings(slug: {eq: "rollout"}) {
                h1
            }
        }`
  );
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  const [groupedExchanges, setGroupedExchanges] = useState(null);
  const [zoomNotificationVisible, setZoomNotificationVisible] = useState(true);

  const [phases] = useState([
    "live",
    "in progress",
    "start in 2023",
  ]);
  const [allExchanges, setAllExchanges] = useState([]);
  const headingsData = getProperty(data, 'strapiPageHeadings');

  const getIcon = (phase) => {
    switch (phase) {
      case "live":
        return "/live.png";
      case "in progress":
        return "/inprogress.png";
      case "start in 2023":
        return "/2023.png";
      default:
        return "";
    }
  };

  const transform = (extent) => {
    return transformExtent(extent, "EPSG:4326", "EPSG:3857");
  };

  useEffect(() => {
    gContext.goResetRegistrationData()
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      proj4.defs(
        "EPSG:27700",
        "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs"
      );

      const map = new Map({
        layers: [
          new TileLayer({
            source: new XYZ({
              url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
            }),
            title: "Google Roads",
            visible: true,
            zIndex: -100,
            className: "googleMaps",
          }),
        ],
        target: "map",
        view: new View({
          extent: transform([-10.867, 76.066, 8.901, 17.145]),
          constrainOnlyCenter: true,
          center: isMobile ? [-179073, 7034508] : [-228073, 7094508],
          zoom: isMobile ? 6.2 : 6.7,
          minZoom: 2,
          enableRotation: false,
        }),
        interactions: DefaultInteractions({
          pinchRotate: false,
        }),
      });

      /* Get a list of all Exchanges and set to state */
      APINO.get(`ProjectModule/v1.0/ftth/exchanges/list`)
        .then((res) => {
          setAllExchanges(res.data.data);
          setGroupedExchanges(groupBy(res.data.data, "website"));
        })
        .catch((e) => {
          console.log("%cError fetching the list of exchanges", "color:red", e);
        });

      ////////////////////////////////////////////////////////////////////////
      // Polygons
      ////////////////////////////////////////////////////////////////////////
      APINO.get(`ProjectModule/v1.0/netomnia/website/polygons`)
        .then((res) => {
          const polygonStyle = new Style({
            stroke: new Stroke({
              color: "#fc3a4c",
              width: 1,
            }),
            fill: new Fill({
              color: "#ce039033",
            }),
          });

          res.data.data.forEach((element) => {
            const parsedGeoJSON = JSON.parse(element.st_asgeojson);
            const rawCoordinates = parsedGeoJSON.coordinates[0];

            const source = proj4.Proj("EPSG:27700");
            const dest = proj4.Proj("EPSG:3857");

            let newCoordinates = [];

            rawCoordinates.forEach((rawCoordinateCollection) => {
              const point = proj4.toPoint(rawCoordinateCollection);
              const trans = proj4.transform(source, dest, point);
              newCoordinates.push([trans.x, trans.y]);
            });

            let feature = new Feature({
              geometry: new Polygon([newCoordinates]),
            });

            let vectorSource = new VectorSource({});
            vectorSource.addFeature(feature);

            let vectorLayer = new VectorLayer({
              source: vectorSource,
              style: polygonStyle,
            });
            map.addLayer(vectorLayer);
          });
        })
        .catch((e) => {
          console.log("%cError fetching the list of exchanges", "color:red", e);
        });

      setMap(map);

      // On map zoom change, remove zoom notification
      map.on("moveend", function (e) {
        const zoom = map.getView().getZoom();
        if (zoom > 8) {
          setZoomNotificationVisible(false);
        } else {
          setZoomNotificationVisible(true);
        }
      });
    }
  }, []);

  /* When allExchanges and Map are updated - render exchanges on the map with the list of selected phases. */
  useEffect(() => {
    if (allExchanges && map) {
      let layerGroup = [];

      allExchanges.forEach((exchange) => {
        if (phases.includes(exchange.website)) {
          if (getIcon(exchange.website) !== "") {
            const iconFeature = new Feature({
              geometry: new Point(fromLonLat([exchange.lon, exchange.lat])),
              name: exchange.exchangename,
            });

            const iconStyle = new Style({
              image: new Icon({
                src: getIcon(exchange.website)
              })
            });

            iconFeature.setStyle(iconStyle);
            const vectorSource = new VectorSource({ features: [iconFeature] });
            const vectorLayer = new VectorLayer({
              source: vectorSource,
              className: "exchangeLayer",
              minZoom: 8,
            });
            layerGroup.push(vectorLayer);
          }
        }
      });

      map.addLayer(
        new LayerGroup({
          name: "exchangeLayerGroup",
          layers: layerGroup,
        })
      );
    }
  }, [phases, allExchanges, map]);

  return (
    <>
      <Seo page="rollout" />
      <PageWrapper>
        <Section className="text-center">
          <Container>
            <Row>
              <Col sm={12} className="text-center pt-5 mt-3 pb-1">
                <MainTitle>{headingsData.h1}</MainTitle>
                <Text>
                  We’re delivering ultrafast, full-fibre broadband access to
                  thousands of homes and businesses across the UK. Explore the
                  map below to find out where we’re currently rolling out our
                  network and which future locations we are targeting.
                </Text>
              </Col>
            </Row>

            {/* Map */}
            <Row className="mt-3 text-center">
              <Col className="col-12">
                <div id="map" ref={mapRef}>

                  {/* Zoom notification */}
                  <span
                    className="rolloutMapZoomNotification"
                    style={{ opacity: zoomNotificationVisible ? 1 : 0 }}
                  >
                    <i className="fa fa-search mr-2" />
                    Zoom in to see Netomnia exchanges
                  </span>

                  {
                    !zoomNotificationVisible &&
                    <div class="map-legend-wrapper">
                      <Row>
                        <Col className="text-left col-12 ml-2 pt-2">
                          <img alt="" src={phaseLive} className="checkboxImage mr-2" />
                          <span htmlFor="phase1">Live</span>
                        </Col>
                        <Col className="text-left col-12 ml-2">
                          <img
                            alt=""
                            src={phaseInprogress}
                            className="checkboxImage mr-12"
                          />
                          <span htmlFor="phase20">In Progress</span>
                        </Col>
                        <Col className="text-left col-12 ml-2 pb-1">
                          <img alt="" src={phase2023} className="checkboxImage mr-2" />
                          <span htmlFor="phase2023">Start in 2024</span>
                        </Col>
                      </Row>
                    </div>
                  }
                </div>
              </Col>
            </Row>

            <Row className="mt-3 text-center">
              <Col className="col-12">
                <Text
                  fontSize="1.1em"
                  fontWeight={300}
                  mt={3}
                >
                  *The rollout map and list of exchanges in the rollout plan are subject to change.
                </Text>
              </Col>
            </Row>



            <div className="rollout-accordion">
              {groupedExchanges && (
                <Accordion>
                  <Accordion.Item eventKey="1" key="accordion-1">
                    <Accordion.Header>Live</Accordion.Header>
                    <Accordion.Body>
                      {groupedExchanges["live"].map((item, index) => {
                        return (
                          <div className="accordion-city">
                            {item.exchangename.toLowerCase()}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" key="accordion-2">
                    <Accordion.Header>In Progress</Accordion.Header>
                    <Accordion.Body>
                      {groupedExchanges["in progress"].map((item, index) => {
                        return (
                          <div className="accordion-city">
                            {item.exchangename.toLowerCase()}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4" key="accordion-4">
                    <Accordion.Header>Start in 2024</Accordion.Header>
                    <Accordion.Body>
                      {groupedExchanges["start in 2023"].map((item, index) => {
                        return (
                          <div className="accordion-city">
                            {item.exchangename.toLowerCase()}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>

            {/*Object.entries(groupBy(allExchanges, 'website'))*/}

            <Row className="mt-5 pt-5 text-center">
              <Col sm={12}>
                <Text
                  as="h4"
                  color="heading"
                  fontSize="1.7em"
                  fontWeight={600}
                  letterSpacing={-0.75}
                  mb={0}
                >
                  To find out if your property is part of the current rollout,
                  enter your postcode
                </Text>
                <Col
                  sm={12}
                  className="text-center check-availability-logo pt-5"
                >
                  <img alt="" src={netomniaCircleLogo} />
                </Col>
                <CheckAvailability
                  isFocused={false}
                  hideLogo={true}
                  customTitle={""}
                />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Rollout;
